export const traitsHats = [
    "Admiral-Hat_5.jpg",
    "Beani-purple-blue_4.jpg",
    "Beani-purple-purple_4.jpg",
    "Beani-red-big_4.jpg",
    "Beani-red-blue_4.jpg",
    "Beani-red-orange_4.jpg",
    "Beanie-blue_10.jpg",
    "Beanie-orange-red_4.jpg",
    "Beanie-purple_10.jpg",
    "Beanie-red-red_4.jpg",
    "Beanie-red_10.jpg",
    "Bow-purple_10.jpg",
    "Bow-red_10.jpg",
    "Cap-Backwards-blue_5.jpg",
    "Cap-Backwards-gray_3.jpg",
    "Cap-Backwards-Red_10.jpg",
    "Cap-Forwards-blue_10.jpg",
    "Cap-Forwards-Purple_10.jpg",
    "Cap-Forwards-red_10.jpg",
    "Cap-Thugbirdz-black_5.jpg",
    "Cap-Thugbirdz-blue_5.jpg",
    "Cap-Thugbirdz-green_5.jpg",
    "Cap-Thugbirdz-orange_5.jpg",
    "Cap-Thugbirdz-red_5.jpg",
    "Cap-Thugbirdz-white_5.jpg",
    "Chef-Hat_4.jpg",
    "Dark-Knight_4.jpg",
    "Doorag-Blue_5.jpg",
    "Doorag-Pirat_5.jpg",
    "Doorag-Red_5.jpg",
    "Fedora-big_4.jpg",
    "Fedora_5.jpg",
    "Flower-with-hair-clip-blue_10.jpg",
    "Flower-with-hair-clip-green_10.jpg",
    "Flower-with-hair-clip_10.jpg",
    "french-cap-green_3.jpg",
    "french-cap_3.jpg",
    "Halo_3.jpg",
    "Headband_5.jpg",
    "Headpones-Purple_5.jpg",
    "Headpones-White_5.jpg",
    "Heisenhat_10.jpg",
    "Horns_3.jpg",
    "Hunter-Hat_5.jpg",
    "Ladies-hat-blue-with-red-bow_4.jpg",
    "Ladies-hat-blue-with-red-flower_4.jpg",
    "Ladies-hat-blue-with-red-flowers_4.jpg",
    "Ladies-hat-blue-with-yellow-bow_2.jpg",
    "Ladies-hat-blue-with-yellow-flower-2_4.jpg",
    "Ladies-hat-blue-with-yellow-flower_4.jpg",
    "Ladies-hat-green-with-purple-bow_4.jpg",
    "Ladies-hat-green-with-purple-flowers_4.jpg",
    "Ladies-hat-green-with-yellow-bow_4.jpg",
    "Ladies-hat-purple-with-purple-flowers_4.jpg",
    "Ladies-hat-purple-with-yellow-bow_4.jpg",
    "Ladies-hat-purple-with-yellow-flower_2.jpg",
    "Mozart_3.jpg",
    "Skipper-Hat_4.jpg",
    "Sombrero_5.jpg",
    "Sorcerer-Hat_5.jpg",
    "Straw-Hat_4.jpg",
    "Straw-Hat_5.jpg",
    "Sun-hat-blue_2.jpg",
    "Thugwarts-Hat_2.jpg",
]
