export const traitsMouthWear = [
    "Lollipop_10.jpg",
    "Pacifier-Blue_5.jpg",
    "Pacifier-Red_5.jpg",
    "Straw_10.jpg",
    "Toy-Horn_10.jpg"
]

export const traitsEyeWear = [
    "3d_4.jpg",
    "paper-trons_3.jpg",
    "shades-blue_4.jpg",
    "shades-gray_4.jpg",
    "shades-purple_4.jpg",
    "shades-red_4.jpg",
    "shades-solana_3.jpg"
]
