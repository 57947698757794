export const traitsClothes = [
    "Bib_4.jpg",
    "Bib_5.jpg",
    "Chef-Jacket_4.jpg",
    "Dirndl-blue_3.jpg",
    "Dirndl-pink_3.jpg",
    "Dirndl-red_3.jpg",
    "Dungarees-longsleeve-check_5.jpg",
    "Dungarees-longsleeve-ringels-red_5.jpg",
    "Dungarees-Shirt-blue_5.jpg",
    "Dungarees-Shirt-darkblue_5.jpg",
    "Dungarees-Shirt-green_5.jpg",
    "Dungarees-Shirt-purple_5.jpg",
    "Dungarees-Shirt-red_5.jpg",
    "Dungarees-Shirt-yellow_5.jpg",
    "lederhosen_4.jpg",
    "Little-Dress-blue_8.jpg",
    "Little-Dress-blue-red_8.jpg",
    "Little-Dress-blue-yellow_8.jpg",
    "Little-Dress-green-blue_8.jpg",
    "Little-Dress-orange_8.jpg",
    "Little-Dress-orange-red_8.jpg",
    "Little-Dress-purple-blue_8.jpg",
    "Little-Dress-purple-yellow_8.jpg",
    "Longsleeve-batman_5.jpg",
    "Longsleeve-ringels-red_5.jpg",
    "Lumberjack-shirt-black_10.jpg",
    "Lumberjack-shirt-red_10.jpg",
    "Pirate_3.jpg",
    "Police-uniform_2.jpg",
    "Racing-Jacket_5.jpg",
    "Robin-Hood_5.jpg",
    "Shirt-Banana_5.jpg",
    "Shirt-blue_6.jpg",
    "Shirt-Blue_10.jpg",
    "Shirt-Burger_5.jpg",
    "shirt-collar-blue_5.jpg",
    "shirt-collar-purple_10.jpg",
    "shirt-collar-red_10.jpg",
    "Shirt-Croco_5.jpg",
    "Shirt-flowers_4.jpg",
    "Shirt-flowers_5.jpg",
    "Shirt-green-hearts_5.jpg",
    "Shirt-green-stripes_5.jpg",
    "Shirt-purple_4.jpg",
    "Shirt-purple-hearts_5.jpg",
    "Shirt-purple-stripes_5.jpg",
    "Shirt-red-hearts_5.jpg",
    "Shirt-red-stripes_5.jpg",
    "Shirt-thugbirdz_3.jpg",
    "shirt-thugbirdz_4.jpg",
    "Shirt-Whale_5.jpg",
    "Shirt-Yellow_10.jpg",
    "Shirt-yellow-excavator_5.jpg",
    "Shirt-yellow-hearts_5.jpg",
    "Shirt-yellow-stripes_5.jpg",
    "Sport-Jacket-blue-white_3.jpg",
    "Sport-Jacket-green-yellow_3.jpg",
    "Sport-Jacket-lightpink_5.jpg",
    "Sport-Jacket-pink_5.jpg",
    "Sport-Jacket-red-yellow_3.jpg",
    "Sport-Jacket-Stars-Stripe_3.jpg"
]
